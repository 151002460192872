<template>
  <lf-modal :title="modalTitle" :close="() => $emit('close')">
    <template v-if="!isSelfFunded" #type>
      <div class="flex space-x-2 capitalize">
        <div
          v-if="clientName"
          class="rounded bg-gray-100 px-2 py-1-5"
          data-cy="client-name"
        >
          {{ clientName }}
        </div>
        <div
          v-if="productTypeName"
          class="rounded bg-gray-100 px-2 py-1-5"
          data-cy="product-type"
        >
          {{ productTypeName }}
        </div>
      </div>
    </template>

    <template #header>
      <div class="flex flex-col w-full space-y-4" data-cy="offer-modal">
        <loader :is-loading="isLoading" />
        <lf-scrollable-menu
          v-if="isSelfFunded || (viewOnly && hasMoreThanOneTemplate)"
          :styling-options="{
            offsetLeftClasses: 'top-0 -left-2 h-[101%]',
            offsetRightClasses: 'top-0 -right-2 h-[101%]'
          }"
        >
          <div
            class="flex items-center border rounded bg-gray-200 p-1 cursor-pointer"
          >
            <template
              v-for="(templateName, templateId) in offerTemplateOptions"
              :key="templateId"
            >
              <span
                v-tooltip="
                  templateId === requestedOfferTemplateId
                    ? $t('OFFER_CUSTOMISATION.REQUESTED_PRODUCT')
                    : ''
                "
                class="px-3 border rounded py-1"
                :class="{
                  'bg-white font-medium text-gray-500': viewOnly
                    ? activeOfferId === templateId
                    : activeOfferTemplateId === templateId,
                  'underline font-semibold':
                    templateId === requestedOfferTemplateId
                }"
                :data-cy="`offer-template-${templateId}`"
                @click="selectProduct(templateId)"
              >
                {{ templateName }}
              </span>
            </template>
          </div>
        </lf-scrollable-menu>
        <div class="flex">
          <lf-tab
            v-show="!hideOfferTab"
            :active="currentTab == TABS[0]"
            data-cy="tab-1"
            @click="selectTab(0)"
          >
            {{ $t("DEALS.DEAL_PROGRESS.OFFER_DETAILS") }}
          </lf-tab>
          <lf-tab
            :active="currentTab == TABS[1]"
            data-cy="tab-2"
            @click="selectTab(1)"
          >
            {{
              isClosing
                ? $t("DEALS.DEAL_PROGRESS.FUNDING_DETAILS")
                : $t("DEALS.DEAL_PROGRESS.REQUIRED_STIPS")
            }}
          </lf-tab>
        </div>
      </div>
    </template>
    <template #content>
      <loader :is-loading="isOfferTemplateLoading" />
      <form @submit="onSubmit" novalidate class="px-6 mt-5">
        <div class="flex-col">
          <div
            v-show="currentTab == TABS[0]"
            class="overflow-y-auto max-h-80-screen pb-2 min-h-50-screen"
          >
            <div class="space-y-4" v-if="!isClosing || currentTab == TABS[0]">
              <wfl-offer-group
                v-for="group in offerFieldGroups"
                :offer-group="group"
                :stage-type="offerTemplateView"
                :view-only="!!viewOnly || !!isClosing"
                :data-cy="`offer-group-${group.id}`"
                :key="group.id"
              />
            </div>
          </div>
          <div
            v-show="currentTab == TABS[1]"
            class="overflow-y-auto max-h-70-screen"
          >
            <template v-if="isClosing">
              <wfl-offer-group
                v-for="group in offerFieldGroups"
                :offer-group="group"
                :stage-type="offerTemplateView"
                :view-only="!!viewOnly"
                :data-cy="`closing-offer-group-${group.id}`"
                :key="group.id"
              />
            </template>
            <stips
              v-else
              ref="stipsList"
              class="px-6 pb-6"
              disable-edit
              hide-title
              :preselect-stips="offerTemplateStips"
              :required-stips="offerStips"
              required-stips-first
              :view-only="viewOnly"
              data-cy="stips-list"
            />
          </div>
        </div>
        <div class="p-6 flex justify-end border-t space-x-4">
          <outline-button @click="closeModal()" data-cy="close-button">
            {{ viewOnly ? $t("COMMON.CLOSE") : $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button
            v-if="!viewOnly"
            type="submit"
            :disabled="isSubmitting"
            data-cy="next-button"
          >
            {{ saveButtonText }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";
import LfTab from "@/components/ui/LfTab.vue";
import { computed, ref, onBeforeMount, nextTick, watch } from "vue";
import { useForm } from "vee-validate";
import { usePromiseWrapper } from "@/hooks/common";
import { useI18n } from "vue-i18n";
import offerTemplateService from "@/services/modules/offerTemplate";
import type {
  FieldGroup,
  OfferDetails,
  OfferTemplateContent
} from "@/models/offers";
import WflOfferGroup from "@/views/deals/components/WflOfferGroup.vue";
import Stips from "@/views/deals/components/Stips.vue";
import type { IFunder, IOffer } from "@/models/funders";
import type { IStip } from "@/models/applications";
import { OfferSaveMode } from "@/enums/offers";
import { dispatchAction } from "@/helpers/vee-validate";
import { useStore } from "vuex";
import { getMissingOfferTemplates } from "@/helpers/offers";
import LfScrollableMenu from "@/components/ui/LfScrollableMenu.vue";
import fundersApiService from "@/services/modules/funders";
import applicationsApiService from "@/services/modules/applications";
import { useDeals } from "@/hooks/deals";
import { useActiveStage, useActiveWorkflowTemplate } from "@/hooks/workflow";
import uniq from "lodash/uniq";
import { OfferTemplateView } from "@/enums/offerTemplates";
import cloneDeep from "lodash/cloneDeep";

interface OfferValuesStore {
  offerValues: Record<string, unknown>;
  fundedValues: Record<string, unknown>;
}

enum Tab {
  DETAILS = "details",
  STIPS = "stips"
}

const props = defineProps<{
  offerTemplateIds?: string[];
  offerDetails?: OfferDetails[];
  placementId?: string | number;
  editMode?: boolean;
  viewOnly?: boolean;
  isClosing?: boolean;
  hideOfferTab?: boolean;
  isSelfFunded?: boolean;
  loadingData?: boolean;
  activeFunderName?: string;
  requestedOfferTemplateId?: string;
}>();

const emit = defineEmits<{
  close: [void];
  offerCreated: [void];
}>();

const { t } = useI18n();
const { getters } = useStore();
const { client } = useDeals();
const { allSelectedOfferFunders } = useActiveWorkflowTemplate();
const { activeStage } = useActiveStage();

const TABS = [Tab.DETAILS, Tab.STIPS];

const currentTab = ref<Tab>(props.isClosing ? TABS[1] : TABS[0]);

const offerTemplate = ref<{
  content: OfferTemplateContent;
  funder_id?: string | null;
  name?: string;
  funder_name?: string;
} | null>(null);
const funder = ref<IFunder | null>(null);

const offerTemplateOptions = ref<Record<string, string>>({});
const offerValuesStore = ref<OfferValuesStore>({
  offerValues: {},
  fundedValues: {}
});

const activeOfferTemplateId = ref(props.offerTemplateIds?.[0]);
const activeOfferId = ref<string | null>(null);
const initialOfferStips = ref<number[]>([]);

const { handleSubmit, resetForm, isSubmitting, setValues, values } = useForm({
  initialValues: props.offerDetails?.length
    ? cloneDeep(props.offerDetails[0].offer)
    : {}
});

const isPlacementStage = computed(() => {
  return activeStage.value?.type === "placement";
});

const fallbackTemplates = computed<Record<string, unknown>[]>(
  () => getters["placements/fallBackOfferTemplatesOptions"] ?? []
);

const offerTemplateView = computed(() => {
  if (currentTab.value === TABS[1] && props.isClosing) {
    return OfferTemplateView.CLOSING;
  }
  return OfferTemplateView.OFFER;
});

const fallbackTemplateIds = computed(() => {
  return fallbackTemplates.value.map((template) => template.id);
});

const saveButtonText = computed(() => {
  const firstButtonTitle = props.isClosing
    ? t("OFFERS.CLOSING.SET_FUNDING_DETAILS")
    : t("DEALS.DEAL_PROGRESS.SET_REQUIRED_STIPS");
  const secondButtonTitle = props.isClosing
    ? t("COMMON.CONFIRM")
    : t("COMMON.SAVE");
  return currentTab.value === TABS[1] ? secondButtonTitle : firstButtonTitle;
});

const offerFieldGroups = computed<FieldGroup[]>(() => {
  return (offerTemplate.value?.content?.groups ?? []).filter(
    (group) =>
      group[offerTemplateView.value].visible &&
      group.fields.some(
        (field) => field[offerTemplateView.value].visibility?.workflow_live
      )
  );
});

const hasMoreThanOneTemplate = computed(() => {
  return Object.keys(offerTemplateOptions.value).length > 1;
});

const getWfbStips = (funderId?: string | null, templateId?: string | null) => {
  if (!funderId || !templateId) {
    return [];
  }
  const stips = allSelectedOfferFunders.value.reduce((acc, funder) => {
    if (funder.id !== funderId) {
      return acc;
    }

    for (const template of funder.offer_templates) {
      if (template.id !== templateId) {
        continue;
      }

      if (template.stips?.length) {
        acc.push(...template.stips);
      }
    }

    return acc;
  }, [] as number[]);

  return uniq(stips);
};

const clientName = computed(() => {
  return (
    props.activeFunderName ??
    funder.value?.full_name ??
    offerTemplate.value?.funder_name ??
    ""
  );
});

const offerTemplateStips = computed(() => {
  const templateStips = fallbackTemplateIds.value.includes(
    activeOfferTemplateId.value
  )
    ? getWfbStips(offerTemplate.value?.funder_id, activeOfferTemplateId.value)
    : (offerTemplate.value?.content?.stips ?? []);
  return props.viewOnly || props.editMode
    ? initialOfferStips.value
    : templateStips;
});

const offerStips = ref<IStip[]>([]);

const updateOffer = async (values: IOffer, actions: unknown) => {
  const payload = {
    offer: {
      ...values,
      offer_template_id: activeOfferTemplateId.value,
      stips: values.stips?.map((stip) => {
        return {
          id: (stip as IStip & { stip_id?: number }).stip_id || stip.id,
          description: stip.description,
          count: stip.count
        };
      })
    },
    mode: props.editMode ? OfferSaveMode.UPDATE : OfferSaveMode.SAVE
  };

  if (props.isSelfFunded && !props.isClosing) {
    await dispatchAction(payload, actions, "applications/saveSelfFundedOffer");
  } else if (props.isClosing) {
    await dispatchAction(payload.offer, actions, "applications/confirmOffer");
  } else {
    await dispatchAction(payload, actions, "applications/saveOffer");
  }

  emit("offerCreated");
  closeModal();
  resetForm();
};

const onSubmit = handleSubmit(async (values, actions) => {
  if (currentTab.value == TABS[0]) {
    selectTab(1);
    return;
  }

  const updatedOffer = {
    ...values,
    id: activeOfferId.value,
    placement_id: props.placementId,
    application_id: getters["applications/active"].id
  };
  await updateOffer(updatedOffer as IOffer, actions);
});

const modalTitle = computed(() => {
  if (props.editMode) {
    return props.isClosing
      ? t("DEALS.DEAL_PROGRESS.EDIT_FUNDING_DETAILS")
      : t("DEALS.DEAL_PROGRESS.EDIT_OFFER");
  }

  if (currentTab.value === TABS[0]) {
    return t("DEALS.DEAL_PROGRESS.SET_OFFER_DETAILS");
  }

  return t("DEALS.DEAL_PROGRESS.SET_REQUIRED_STIPS");
});

const productTypeName = computed(() => {
  return offerTemplate.value?.name ?? "";
});

const selectTab = async (tab: number) => {
  const prevValues = cloneDeep(values);
  currentTab.value = TABS[tab];
  if (props.isClosing) {
    currentTab.value == TABS[1]
      ? (offerValuesStore.value.offerValues = prevValues)
      : (offerValuesStore.value.fundedValues = prevValues);
    const valueToSet =
      tab == 0
        ? offerValuesStore.value.offerValues
        : offerValuesStore.value.fundedValues;
    await nextTick();
    setValues(cloneDeep(valueToSet));
  }
};

const localOfferDetails = ref<OfferDetails[]>(
  cloneDeep(props.offerDetails ?? [])
);

const { fetchWrapper: getOfferTemplate, loading: isOfferTemplateLoading } =
  usePromiseWrapper(async (id: string) => {
    const offerTemplatesResponse = await offerTemplateService.getTemplate(id);
    offerTemplate.value = offerTemplatesResponse.data;
  });

const { fetchWrapper: getOffer, loading: isOfferLoading } = usePromiseWrapper(
  async (id: string) => {
    const offerResponse = await applicationsApiService.getOffer(id);
    localOfferDetails.value.map((offerDetails) => {
      if (offerDetails.offer_id === id) {
        offerStips.value = offerResponse.stips;
        initialOfferStips.value = offerResponse.stips.reduce(
          (acc: number[], stip) => {
            if (stip.stip_id) {
              acc.push(stip.stip_id);
            }
            return acc;
          },
          []
        );
        setValues({ stips: initialOfferStips.value });
        return offerResponse.details;
      }
      return offerDetails;
    });

    offerTemplate.value = {
      content: offerResponse.details.content,
      name: offerResponse.details.offer_template.name,
      funder_name: offerResponse.placement?.funder.full_name,
      funder_id: offerResponse.placement?.funder.id
    };
  }
);

const {
  fetchWrapper: getOfferTemplatesList,
  loading: isOfferTemplatesListLoading
} = usePromiseWrapper(async (templateIds: string[]) => {
  const offerTemplatesResponse = templateIds.length
    ? await offerTemplateService.getTemplates({
        ids: templateIds
      })
    : { data: [] };

  const latestUpdatedTemplate = offerTemplatesResponse.data.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  )[0];

  const baseTemplateOptions = offerTemplatesResponse.data.reduce(
    (acc: Record<string, string>, offerTemplate) => {
      acc[offerTemplate.id] = offerTemplate.name;
      return acc;
    },
    {}
  );

  const defaultTemplates = getMissingOfferTemplates(
    offerTemplatesResponse.data,
    funder.value
  );

  const defaultTemplateOptions = defaultTemplates.reduce(
    (acc: Record<string, string>, offerTemplate) => {
      acc[offerTemplate.id] = offerTemplate.name;
      return acc;
    },
    {}
  );

  offerTemplateOptions.value = {
    ...baseTemplateOptions,
    ...defaultTemplateOptions
  };

  activeOfferTemplateId.value =
    props.requestedOfferTemplateId ??
    latestUpdatedTemplate?.id ??
    defaultTemplates[0].id;
});

const closeModal = () => {
  emit("close");
};

const selectProduct = async (templateId: string) => {
  activeOfferTemplateId.value = templateId;
  if (!localOfferDetails.value?.length) {
    return getOfferTemplate(templateId);
  }
  activeOfferId.value = templateId;
  const newTemplateDataIndex = localOfferDetails.value.findIndex(
    (offerDetails) => offerDetails.offer_id === templateId
  );

  if (newTemplateDataIndex !== -1) {
    if (
      !localOfferDetails.value[newTemplateDataIndex].content ||
      isPlacementStage.value
    ) {
      await getOffer(localOfferDetails.value[newTemplateDataIndex].offer_id);
    } else {
      offerTemplate.value = {
        content: localOfferDetails.value[newTemplateDataIndex].content,
        name: `Offer ${newTemplateDataIndex + 1}`
      };
    }
    activeOfferId.value =
      localOfferDetails.value[newTemplateDataIndex].offer_id;
    activeOfferTemplateId.value =
      localOfferDetails.value[newTemplateDataIndex].offer_template_id;
  }
  await nextTick();
  setValues(localOfferDetails.value[newTemplateDataIndex].offer);
};

const setOfferDetails = async () => {
  activeOfferTemplateId.value = localOfferDetails.value[0].offer_template_id;
  activeOfferId.value = localOfferDetails.value[0].offer_id;
  offerTemplateOptions.value = localOfferDetails.value.reduce(
    (acc: Record<string, string>, offerDetails, index) => {
      acc[offerDetails.offer_id] = `Offer ${index + 1}`;
      return acc;
    },
    {}
  );
  if (!localOfferDetails.value[0].content || isPlacementStage.value) {
    await getOffer(localOfferDetails.value[0].offer_id);
  } else {
    offerTemplate.value = {
      content: localOfferDetails.value[0].content,
      name: localOfferDetails.value[0].offer_template.name
    };
  }
  await nextTick();
  if (props.isClosing) {
    offerValuesStore.value = {
      offerValues: localOfferDetails.value[0].offer,
      fundedValues:
        localOfferDetails.value[0].closing ?? localOfferDetails.value[0].offer
    };
  }
  const valuesToSet = props.isClosing
    ? offerValuesStore.value.fundedValues
    : localOfferDetails.value[0].offer;
  setValues(valuesToSet);
};

const isLoading = computed(() => {
  return (
    isOfferTemplatesListLoading.value ||
    isOfferTemplateLoading.value ||
    isOfferLoading.value ||
    props.loadingData
  );
});

onBeforeMount(async () => {
  if (props.offerTemplateIds) {
    if (props.isSelfFunded) {
      if (client.value?.linked_funder_id) {
        funder.value = await fundersApiService.getFunder(
          client.value.linked_funder_id
        );
      }

      const customTemplates = props.offerTemplateIds.filter(
        (id) => !fallbackTemplateIds.value.includes(id)
      );
      await getOfferTemplatesList(customTemplates);
    }
    if (activeOfferTemplateId.value) {
      await getOfferTemplate(activeOfferTemplateId.value);
      if (offerTemplate.value?.funder_id) {
        const funderResponse = await fundersApiService.getFunder(
          offerTemplate.value?.funder_id
        );
        funder.value = funderResponse;
      }
    }
  }
  if (localOfferDetails.value.length) {
    setOfferDetails();
  }
});

watch(
  () => props.offerDetails,
  (newVal) => {
    localOfferDetails.value = cloneDeep(newVal ?? []);
    if (props.offerDetails && props.offerDetails.length) {
      setOfferDetails();
    }
  }
);
</script>
