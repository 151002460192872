import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import type { IPaginatedResponse } from "@/models/common";
import type {
  EmptyOfferTemplate,
  GetOfferTemplatesParams,
  OfferTemplate
} from "@/models/offers";
import { formatString } from "@/helpers/formatting";

class OfferTemplateService {
  async getTemplate(id: string) {
    const response = await axiosClient.get<{ data: OfferTemplate }>(
      formatString(endpoints.OFFER_TEMPLATES.SINGLE_TEMPLATE, id)
    );
    return response.data;
  }

  async getTemplates(params?: GetOfferTemplatesParams) {
    const response = await axiosClient.get<IPaginatedResponse<OfferTemplate>>(
      endpoints.OFFER_TEMPLATES.ALL,
      { params }
    );
    return response.data;
  }

  async createTemplate(data: OfferTemplate | EmptyOfferTemplate) {
    const response = await axiosClient.post<{ data: OfferTemplate }>(
      endpoints.OFFER_TEMPLATES.ALL,
      data
    );
    return response.data.data;
  }
  async updateTemplate(data: OfferTemplate | EmptyOfferTemplate) {
    const response = await axiosClient.patch<{ data: OfferTemplate }>(
      formatString(endpoints.OFFER_TEMPLATES.SINGLE_TEMPLATE, data.id),
      data
    );
    return response.data.data;
  }
}

const offerTemplateService = new OfferTemplateService();
export default offerTemplateService;
